<aecom-form *ngIf="isLoaded" columns="12" class="contentcontainer">
  <aecom-form-group columnSpan="12" columns="2" title="General">
    <aecom-form-item [label]="'From (Sender)'" columnSpan="1">
      <div class="itemText">
        {{ orignator }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Letter Date'" columnSpan="1">
      <div class="itemText">
        {{ letterDate }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'To (Recipient)'" columnSpan="1">
      <div class="itemText">
        {{ recipent }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Organization Reference Number'" columnSpan="1">
      <div class="itemText">
        {{ organizationReferenceNumber }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Reference'" columnSpan="2">
      <div class="itemText">
        {{ reference }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Subject'" columnSpan="2">
      <div class="itemText">
        {{ subject }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Additional Notes'" columnSpan="2">
      <div class="itemText">
        {{ note }}
      </div>
    </aecom-form-item>
  </aecom-form-group>
  
  <aecom-form-group columnSpan="12" columns="2" title="Documents" class="marginTop">
    <aecom-form-item [label]="'Correspondence File'" columnSpan="1">
      <bbj-file-list
        *ngIf="correspondenceFile.length > 0; else notAvailable"
        [fileList]="correspondenceFile"
        [maxCharacters]="maxCharacters"
        [allowMovingFiles]="false"
        [showSeparator]="false"
      ></bbj-file-list>
    </aecom-form-item>
    <aecom-form-item [label]="'Attachments'" columnSpan="1">
      <bbj-file-list
        *ngIf="attachments.length > 0; else notAvailable"
        [fileList]="attachments"
        [maxCharacters]="maxCharacters"
        [allowMovingFiles]="false"
        [showSeparator]="false"
      ></bbj-file-list>
    </aecom-form-item>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
