/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable security/detect-object-injection */
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import LoadingService from "@services/loading.service";
import _ from "lodash";
import {
  CorrespondenceItemService,
  ICorrDocumentDownload,
  ICorrListItem,
} from "@api";
import IViewPrepare from "@models/IViewPrepaer";
import LocalContractUserService from "@services/local-contractUser.service";
import CorrespondenceRole from "@models/CorrespondenceRole";
import CorrView from "@models/corrView";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import CorrespondenceUpdateBase from "@models/CorrespondenceUpdateBase";
import { HttpErrorResponse } from "@angular/common/http";
import BaseFunctionService from "@services/baseFunction.service";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export default class ViewComponent implements OnInit {
  entity: ICorrListItem;

  currentUser: string;

  defaultTab = "details";

  docs: ICorrDocumentDownload[] = [];

  corr: CorrView;

  isDbAdmin = false;

  title = "Response";

  clipboardText = "NO Responded YET";

  userSelectTitle = "cc";

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public correspondenceItemService: CorrespondenceItemService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    this.currentUser = this.authService.user.Id;
    const role = this.localContractUserService.currentUserContractRole;
    this.isDbAdmin = role === CorrespondenceRole.DBAdmin;
    const isDoc = role === CorrespondenceRole.DocControl;
    if (data) {
      const { item, documents } = data;
      this.entity = item;
      this.docs = documents;
      this.corr = new CorrView(
        item,
        documents,
        this.localContractUserService.getItems(),
      );
      this.baseFunctionService.initCCUsers(this.userSelectTitle, true);
      if (
        isDoc !== true &&
        this.entity.Status !== CorrespondenceStatus.Filed &&
        this.entity.Status !== CorrespondenceStatus.Responded
      ) {
        this.title = "Updates";
        this.clipboardText = "Received";
      }
      if (
        this.isDbAdmin &&
        this.entity.BallInCourt.includes(CorrespondenceRole.DBAdmin) &&
        this.entity.Viewed !== true &&
        (this.entity.Status === CorrespondenceStatus.Filed ||
          this.entity.Status === CorrespondenceStatus.Responded)
      ) {
        const viewCorr = new CorrespondenceUpdateBase(
          this.entity.ContractId,
          this.entity,
        );

        this.correspondenceItemService.corrUpdate(viewCorr).subscribe(
          (r) => {
            console.log(r);
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          },
        );
      }
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.entity.ContractId}/list`);
  }
}
